@import '~app/mixins';

.flex {
  display: flex;

  * {
    align-self: center;
  }
}

.address {
  @include primary-color();
}

.addressLink {
  color: $color-teal;
}
